<template>
    <b-card>
        <b-card-title> 能耗 </b-card-title>
        <div
            style="height: calc(100vh - 221px)"
            class="d-flex flex-column justify-content-between"
        >
            <div>
                <div
                    v-for="(
                        energyItem, energyKey, energyIndex
                    ) in projectEnergy"
                    :key="energyKey"
                    :class="energyIndex % 4 === 3 ? '' : 'mb-1'"
                >
                    <div
                        class="d-flex align-items-center justify-content-between"
                    >
                        <div>
                            <h3 class="my-0">
                                <span>{{ energyItem.value }}</span>
                                <small class="ml-25">kWh</small>
                            </h3>
                            <label class="my-0">
                                {{ energyItem.label }}
                            </label>
                        </div>

                        <b-badge
                            :variant="
                                energyItem.percent > 0
                                    ? 'light-danger'
                                    : 'light-success'
                            "
                        >
                            {{ energyItem.percent }}%

                            <feather-icon
                                :class="
                                    energyItem.percent > 0
                                        ? 'text-danger'
                                        : 'text-success'
                                "
                                :icon="
                                    energyItem.percent > 0
                                        ? 'TrendingUpIcon'
                                        : 'TrendingDownIcon'
                                "
                            />
                        </b-badge>
                    </div>
                </div>
            </div>

            <hr class="offsetMargin" />

            <b-row>
                <b-col
                    cols="5"
                    class="d-flex align-items-center justify-content-center"
                >
                    <b-avatar size="72" variant="light-success">
                        <h1 class="font-digit text-success my-0">
                            CO<sub>2</sub>
                        </h1>
                    </b-avatar>
                </b-col>

                <b-col cols="7">
                    <label>今日碳排放</label>
                    <h1 class="my-0 text-truncate">
                        {{ co2.today }}
                    </h1>
                    <p class="mb-50">
                        <small>kgCO<sub>2</sub></small>
                    </p>

                    <b-badge
                        v-if="projectEnergy.day"
                        :variant="
                            projectEnergy.day.percent > 0
                                ? 'light-danger'
                                : 'light-success'
                        "
                    >
                        <span>{{ projectEnergy.day.percent }}%</span>
                        <feather-icon
                            :icon="
                                projectEnergy.day.percent > 0
                                    ? 'TrendingUpIcon'
                                    : 'TrendingDownIcon'
                            "
                        />
                    </b-badge>
                </b-col>
            </b-row>

            <hr class="offsetMargin" />

            <div>
                <b-card-sub-title>分项能耗</b-card-sub-title>
                <div
                    id="project-detail-energy-pie"
                    style="height: 11rem; min-height: 10rem; max-height: 12rem"
                ></div>
            </div>

            <hr class="offsetMargin" />

            <div>
                <b-card-sub-title>逐月能耗</b-card-sub-title>
                <div
                    id="project-detail-energy-column"
                    style="height: 11rem; min-height: 10rem; max-height: 12rem"
                ></div>
            </div>
        </div>
    </b-card>
</template>

<script>
import {
    computed,
    onMounted,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api";
import { routerParams } from "@/libs/utils/routerParams";
import { $themeColors } from "@themeConfig";
import { Column, Pie, RadialBar } from "@antv/g2plot";
import store from "@/store";

export default {
    name: "Energy",
    props: {
        project_energy: [Object],
    },
    setup(props) {
        const event = reactive({
            project_id: computed(() => {
                return routerParams("project_id");
            }),
            projectEnergy: computed(() => {
                return props.project_energy;
            }),
            co2_coefficient: computed(() => {
                const project_data = store.state.projects.selected.project_data;
                return project_data && project_data.co2_coefficient
                    ? project_data.co2_coefficient
                    : 0.8843;
            }),
            co2: {
                today: 0,
                last: 0,
            },
            piePlot: null,
            radialPlot: null,
            columnPlot: null,
        });

        watch(
            () => event.projectEnergy,
            (projectEnergy) => {
                event.co2.today =
                    Math.round(
                        parseFloat(projectEnergy.day.value) *
                            event.co2_coefficient *
                            100
                    ) / 100;
                event.co2.last =
                    Math.round(
                        parseFloat(projectEnergy.day.last) *
                            event.co2_coefficient *
                            100
                    ) / 100;
                const energy_list = projectEnergy.year.list;
                let energy_type_list = {};
                let energy_month_list = {};
                energy_list.map((energyItem) => {
                    energyItem["time"] = energyItem["time"].replace(
                        "-01 00:00:00",
                        ""
                    );
                    if (!energy_type_list[energyItem.egid]) {
                        energy_type_list[energyItem.egid] = {
                            type: "",
                            value: 0,
                        };
                    }
                    if (!energy_month_list[energyItem.time]) {
                        energy_month_list[energyItem.time] = {
                            time: "",
                            value: 0,
                        };
                    }
                    energy_type_list[energyItem.egid]["type"] =
                        energyItem.egname;
                    energy_type_list[energyItem.egid]["value"] += parseInt(
                        energyItem.value
                    );
                    energy_month_list[energyItem.time]["time"] =
                        energyItem.time;
                    energy_month_list[energyItem.time]["value"] += parseInt(
                        energyItem.value
                    );
                });
                event.piePlot.changeData(Object.values(energy_type_list));
                event.columnPlot.changeData(Object.values(energy_month_list));
                // const chartData = [
                // 	{
                // 		label: projectEnergy.day.last_label,
                // 		value: projectEnergy.day.last,
                // 	},
                // 	{
                // 		label: projectEnergy.day.label,
                // 		value: projectEnergy.day.value,
                // 	},
                // ];

                // event.radialPlot.changeData(chartData)
            }
        );

        const energyChartInit = () => {
            const radialPlot = new RadialBar("energy-chart-day", {
                theme: {
                    colors10: Object.values($themeColors),
                },
                data: [],
                xField: "label",
                yField: "value",
                // maxAngle: 90, //最大旋转角度,
                radius: 0.8,
                innerRadius: 0.2,
                maxBarWidth: 8,
                maxAngle: 210,
                barStyle: {
                    lineCap: "round",
                    fillOpacity: 1,
                    lineWidth: 1,
                },
                barBackground: {
                    style: {
                        fill: "#000",
                        fillOpacity: 0.1,
                    },
                },
                colorField: "label",
                // color: ['#00cfe8','#28c76f'],
                tooltip: {
                    formatter: (datum) => {
                        return {
                            name: datum.label,
                            value: datum.value + " kWh",
                        };
                    },
                },
            });
            radialPlot.render();
            event.radialPlot = radialPlot;
        };
        const pieChartInit = () => {
            const piePlot = new Pie("project-detail-energy-pie", {
                theme: {
                    colors10: Object.values($themeColors),
                },
                appendPadding: 10,
                data: [],
                angleField: "value",
                colorField: "type",
                radius: 1,
                innerRadius: 0.6,
                pieStyle: {
                    lineWidth: 0,
                },
                label: {
                    type: "inner",
                    offset: "-50%",
                    autoRotate: false,
                    style: {
                        textAlign: "center",
                        fill: "#c5c8ce",
                    },
                    formatter: ({ percent }) =>
                        `${(percent * 100).toFixed(0)}%`,
                },
                legend: {
                    position: "left",
                    itemName: {
                        style: {
                            fill: "#808695",
                        },
                    },
                },
                statistic: {
                    title: {
                        formatter: () => "合计",
                        offsetY: -8,
                        style: {
                            fontSize: "0.9rem",
                            color: "",
                        },
                    },
                    content: {
                        offsetY: -4,
                        style: {
                            fontSize: "1.286rem",
                            /* fontFamily: "LcdD", */
                            fontWeight: "normal",
                            color: "",
                        },
                    },
                },
                // 添加 中心统计文本 交互
                interactions: [
                    {
                        type: "element-selected",
                    },
                    {
                        type: "element-highlight",
                    },
                    {
                        type: "pie-statistic-active",
                        cfg: {
                            start: [
                                {
                                    trigger: "element:mouseenter",
                                    action: "pie-statistic:change",
                                },
                                {
                                    trigger: "legend-item:mouseenter",
                                    action: "pie-statistic:change",
                                },
                            ],
                            end: [
                                {
                                    trigger: "element:mouseleave",
                                    action: "pie-statistic:reset",
                                },
                                {
                                    trigger: "legend-item:mouseleave",
                                    action: "pie-statistic:reset",
                                },
                            ],
                        },
                    },
                ],
            });
            piePlot.render();
            event.piePlot = piePlot;
        };
        const columnChartInit = () => {
            const columnPlot = new Column("project-detail-energy-column", {
                theme: {
                    colors10: Object.values($themeColors),
                },
                data: [],
                xField: "time",
                yField: "value",
                color: () => {
                    return $themeColors["primary"];
                },
                label: {
                    position: "middle",
                    style: {
                        fill: "#FFFFFF",
                        opacity: 0.6,
                    },
                },
                xAxis: {
                    label: {
                        autoHide: true,
                        autoRotate: false,
                    },
                    line: null,
                    grid: null,
                    tickLine: null,
                },
                yAxis: {
                    line: null,
                    grid: null,
                    label: null,
                },
                columnStyle: {
                    radius: [5, 5, 5, 5],
                },
                meta: {
                    time: {
                        alias: "月份",
                    },
                    value: {
                        alias: "耗电量",
                    },
                },
            });

            columnPlot.render();
            event.columnPlot = columnPlot;
        };
        onMounted(() => {
            // energyChartInit()
            pieChartInit();
            columnChartInit();
        });
        return {
            ...toRefs(event),
        };
    },
};
</script>

<style lang="scss" scoped>
.offsetMargin {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}
</style>
