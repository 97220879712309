<template>
    <b-card>
        <b-card-title> 环境</b-card-title>
        <div
            style="height: calc(100vh - 221px)"
            class="d-flex flex-column justify-content-between"
        >
            <!-- 室内 -->
            <div
                v-for="(item, index) in chartNames"
                :key="index"
                :class="index % 4 === 0 ? 'mt-1' : 'mt-2'"
            >
                <b-card-sub-title
                    >{{ item.title }}
                    <small class="text-muted" v-html="item.unit"></small>
                    <div class="float-right">
                        <label>当前</label>
                        <b-badge variant="light-primary" class="ml-50"
                            >{{ latestParams[item.key] || "N/A" }}
                        </b-badge>
                    </div>
                </b-card-sub-title>

                <div
                    :id="'project-detail-environment-' + item.key"
                    style="
                        height: 7.5rem;
                        max-height: 8rem;
                        min-height: 7rem;
                        margin-top: 1rem;
                    "
                ></div>
            </div>

            <hr class="offsetMargin" />

            <!-- 室外 -->
            <div>
                <label>
                    <span>{{ projectInfo.city || "N/A" }}</span>
                    <span class="ml-2"
                        >{{
                            projectInfo.lng
                                ? projectInfo.lng.toFixed(2)
                                : "N/A"
                        }}° E,
                        {{
                            projectInfo.lat
                                ? projectInfo.lat.toFixed(2)
                                : "N/A"
                        }}° N</span
                    >
                </label>
            </div>

            <b-row class="mt-50">
                <b-col cols="6">
                    <h4 class="my-0">
                        {{
                            projectParams["current"]["OUTTEMP"] ||
                            projectParams["current"]["OUTTEMP"] === 0
                                ? projectParams["current"]["OUTTEMP"].toFixed(1)
                                : "N/A"
                        }}
                        <small class="text-muted">℃</small>
                    </h4>
                    <label>室外温度</label>
                </b-col>

                <b-col cols="6">
                    <h4 class="my-0">
                        {{
                            projectParams["current"]["OUTDQY"] ||
                            projectParams["current"]["OUTDQY"] === 0
                                ? projectParams["current"]["OUTDQY"].toFixed(1)
                                : "N/A"
                        }}

                        <small class="text-muted"> hPa</small>
                    </h4>
                    <label>大气压</label>
                </b-col>
            </b-row>

            <b-row class="mt-50">
                <b-col cols="6">
                    <h4 class="my-0">
                        {{
                            projectParams["current"]["OUTHUMI"] ||
                            projectParams["current"]["OUTHUMI"] === 0
                                ? projectParams["current"]["OUTHUMI"].toFixed(1)
                                : "N/A"
                        }}

                        <small class="text-muted"> %</small>
                    </h4>
                    <label class="mb-0">室外湿度</label>
                </b-col>

                <b-col cols="6">
                    <h4 class="my-0">
                        {{
                            projectParams["current"]["OUTFZD"] ||
                            projectParams["current"]["OUTFZD"] === 0
                                ? projectParams["current"]["OUTFZD"].toFixed(1)
                                : "N/A"
                        }}
                        <small class="text-muted"> W/m<sup>2</sup></small>
                    </h4>
                    <label class="mb-0">太阳辐照</label>
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>

<script>
import { Area } from "@antv/g2plot";
import { $themeColors } from "@themeConfig";
import {
    computed,
    onMounted,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api";
import { routerParams } from "@/libs/utils/routerParams";

export default {
    name: "Environment",
    props: {
        project_params: [Object],
        project_info: [Object],
    },
    setup(props) {
        const event = reactive({
            projectParams: computed(() => {
                return (
                    props.project_params || {
                        list: {},
                        current: {},
                    }
                );
            }),
            projectInfo: computed(() => {
                return (
                    props.project_info || { city: null, lng: null, lat: null }
                );
            }),
            project_id: computed(() => {
                return routerParams("project_id");
            }),
            chartNames: computed(() => {
                let keys = [
                    { key: "OUTTEMP", title: "温度", unit: "℃" },
                    { key: "OUTHUMI", title: "湿度", unit: "%" },
                    { key: "OUTFZD", title: "辐照", unit: "W/m<sub>2</sub>" },
                    {
                        key: "OUTPM2.5",
                        title: "PM2.5",
                        unit: "μg/m<sup>3</sup>",
                    },
                ];
                if (parseInt(event.project_id) === 7) {
                    keys = [
                        { key: "R315RT", title: "温度", unit: "℃" },
                        { key: "R315RH", title: "湿度", unit: "%" },
                        { key: "R315CO2", title: "CO2", unit: "ppm" },
                        {
                            key: "Room319_PM2.5",
                            title: "PM2.5",
                            unit: "μg/m<sup>3</sup>",
                        },
                    ];
                }
                return keys;
            }),
            charts: {},
            latestParams: {},
        });
        watch(
            () => event.projectParams,
            (projectParams) => {
                console.log("数据发送变化projectParams", projectParams);
                const timeList = [
                    "00:00",
                    "01:00",
                    "02:00",
                    "03:00",
                    "04:00",
                    "05:00",
                    "06:00",
                    "07:00",
                    "08:00",
                    "09:00",
                    "10:00",
                    "11:00",
                    "12:00",
                    "13:00",
                    "14:00",
                    "15:00",
                    "16:00",
                    "17:00",
                    "18:00",
                    "19:00",
                    "20:00",
                    "21:00",
                    "22:00",
                    "23:00",
                ];
                let latestParams = {};

                event.chartNames.map((name) => {
                    const key = name.key;
                    let exist_hours = [];
                    let projectParamsItem = Object.assign(
                        [],
                        Object.values(projectParams.list[key])
                    );
                    projectParamsItem.map((item) => {
                        exist_hours.push(item.time);
                        if (item.value) {
                            latestParams[key] = item.value;
                        }
                    });
                    timeList.map((hour) => {
                        if (!exist_hours.includes(hour)) {
                            projectParamsItem.push({
                                name: key,
                                value: null,
                                time: hour,
                            });
                        }
                    });
                    event.charts[key].changeData(projectParamsItem);
                });
                event.latestParams = latestParams;
                console.log("event.latestParams", latestParams);
            }
        );
        const chartInit = () => {
            event.chartNames.map((item) => {
                console.log("chartNames遍历", item);
                const area = new Area(
                    "project-detail-environment-" + item.key,
                    {
                        data: [],
                        xField: "time",
                        yField: "value",
                        xAxis: {
                            label: {
                                autoHide: true,
                                autoRotate: false,
                            },
                            line: null,
                            grid: null,
                            tickLine: null,
                        },
                        yAxis: {
                            nice: true,
                            grid: {
                                line: {
                                    style: {
                                        stroke: "#82868b",
                                        lineWidth: 1,
                                        lineDash: [1, 6],
                                        strokeOpacity: 0.7,
                                    },
                                },
                            },
                        },
                        smooth: true,
                        meta: {
                            time: {
                                alias: "时间",
                            },
                            value: {
                                alias: "数值",
                            },
                        },
                    }
                );
                area.render();
                event.charts[item.key] = area;
            });
        };
        onMounted(() => {
            console.log("首页propos", props.project_params);
            chartInit();
        });
        return {
            ...toRefs(event),
        };
    },
};
</script>

<style lang="scss" scoped>
.offsetMargin {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}
</style>
