<template>
    <b-card style="height: calc(70vh - 137px)">
        <b-card-title> 运维 </b-card-title>

        <!-- 工单统计 -->
        <b-row>
            <b-col
                class="text-center cursor-pointer"
                cols="3"
                @click="showDetails('agent')"
            >
                <b-avatar rounded variant="light-primary">
                    <b-icon-zoom-in />
                </b-avatar>
                <label>诊断</label>
                <h4 class="my-0">
                    {{ projectOperation.agent.length }}
                </h4>
            </b-col>

            <b-col
                class="text-center cursor-pointer"
                cols="3"
                @click="showDetails('alarm')"
            >
                <b-avatar rounded variant="light-danger">
                    <b-icon-bell-fill />
                </b-avatar>
                <label>报警</label>
                <h4 class="my-0">
                    {{ projectOperation.alarm.length }}
                </h4>
            </b-col>

            <b-col
                class="text-center cursor-pointer"
                cols="3"
                @click="showDetails('inspection')"
            >
                <b-avatar rounded variant="light-success">
                    <b-icon-check2-square />
                </b-avatar>
                <label>巡检</label>
                <h4 class="my-0">
                    {{ projectOperation.inspection.length }}
                </h4>
            </b-col>

            <b-col
                class="text-center cursor-pointer"
                cols="3"
                @click="showDetails('repair')"
            >
                <b-avatar rounded variant="light-warning">
                    <b-icon-wrench />
                </b-avatar>
                <label>报修</label>
                <h4 class="my-0">
                    {{ projectOperation.repair.length }}
                </h4>
            </b-col>
        </b-row>

        <hr class="mt-1 mb-0 offsetMargin"  />

        <!-- 滚动提示 -->
        <vue-perfect-scrollbar
            id="project-detail-operation-scroll-notice"
            style="height: calc(100% - 138px)"
            @mouseenter="scroll_stop('#project-detail-operation-scroll-notice')"
            @mouseleave="
                scroll_active('#project-detail-operation-scroll-notice')
            "
        >
            <app-timeline>
                <template v-if="selected === 'agent'">
                    <app-timeline-item
                        v-for="(item, index) in projectOperation['agent']"
                        :key="'agent_' + index"
                        :time="item.time"
                        :title="item.Description"
                        :variant="
                            item.Degree === '重要'
                                ? 'danger'
                                : item.Degree === '一般'
                                ? 'info'
                                : 'warning'
                        "
                        class="pb-1"
                    >
                    </app-timeline-item>
                </template>
                <template v-else-if="selected === 'alarm'">
                    <app-timeline-item
                        v-for="(item, index) in projectOperation['alarm']"
                        :key="'alarm_' + index"
                        :time="item.complete_data"
                        :title="item.srname"
                        :variant="
                            item.grade === '重要'
                                ? 'danger'
                                : item.grade === '一般'
                                ? 'info'
                                : 'warning'
                        "
                        class="pb-1"
                    >
                    </app-timeline-item>
                </template>
                <template v-else-if="selected === 'repair'">
                    <app-timeline-item
                        v-for="(item, index) in projectOperation['repair']"
                        :key="'repair_' + index"
                        :time="momentFmt(item.add_time)"
                        :title="
                            item.repair_addr_name + ' ' + item.repair_type_name
                        "
                        class="pb-1"
                        variant="info"
                    >
                    </app-timeline-item>
                </template>
                <template v-else>
                    <app-timeline-item
                        v-for="(item, index) in projectOperation['inspection']"
                        :key="'inspection_' + index"
                        :time="'执行时间: ' + item.NEXTDT"
                        :title="item.POSIRANGE + item.PLANNAME"
                        class="pb-1"
                        variant="info"
                    ></app-timeline-item>
                </template>
            </app-timeline>
        </vue-perfect-scrollbar>
    </b-card>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import {
    computed,
    onMounted,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api";
import { momentFmt } from "@/libs/utils/moment";
import { scroll_active, scroll_stop } from "@/libs/utils/autoScroll";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
    name: "Operation",
    props: {
        project_operation: [Object],
    },
    setup(props) {
        const event = reactive({
            projectOperation: computed(() => {
                return props.project_operation;
            }),
            selected: "agent",
        });
        const showDetails = (type) => {
            console.log(type);
            event.selected = type;
            // console.log(type, event.projectOperation[type])
        };
        watch(
            () => event.projectOperation,
            () => {
                for (let key in event.projectOperation) {
                    if (event.projectOperation[key].length > 0) {
                        event.selected = key;
                        break;
                    }
                }
            }
        );
        onMounted(() => {
            scroll_active("#project-detail-operation-scroll-notice");
        });
        return {
            ...toRefs(event),
            showDetails,
            momentFmt,
            scroll_active,
            scroll_stop,
        };
    },
    components: {
        AppTimeline,
        AppTimelineItem,
        VuePerfectScrollbar,
    },
};
</script>

<style lang="scss">
.timeline-item {
    padding-left: 1.5rem !important;

    div > h6 {
        font-size: 0.9rem !important;
    }
}

.offsetMargin {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}
</style>
