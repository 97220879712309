<template>
    <b-row style="margin-bottom: 2rem">
        <b-col cols="4">
            <div class="d-flex align-items-center">
                <b-avatar class="mr-1" rounded variant="light-danger">
                    <b-icon-rulers />
                </b-avatar>
                <b-card-text>
                    <h4 class="my-0">
                        <span>{{ projectInfo.building_area || "N/A" }}</span>
                        <small class="text-muted ml-50">m<sup>2</sup></small>
                    </h4>
                    <label class="my-0">建筑面积</label>
                </b-card-text>
            </div>
        </b-col>

        <b-col cols="4">
            <div class="d-flex align-items-center">
                <b-avatar class="mr-1" rounded variant="light-success">
                    <b-icon-layers-fill />
                </b-avatar>
                <b-card-text>
                    <h4 class="my-0">
                        <span>{{ projectInfo.building_level || "N/A" }}</span>
                    </h4>
                    <label class="my-0">建筑楼层</label>
                </b-card-text>
            </div>
        </b-col>

        <b-col cols="4">
            <div class="d-flex align-items-center">
                <b-avatar class="mr-1" rounded variant="light-warning">
                    <b-icon-calendar-date-fill />
                </b-avatar>
                <b-card-text>
                    <h4 class="my-0">
                        <span>{{
                            projectInfo.building_time
                                ? momentJs(projectInfo.building_time).format(
                                      "Y"
                                  )
                                : "N/A"
                        }}</span>
                    </h4>
                    <label class="my-0">竣工年份</label>
                </b-card-text>
            </div>
        </b-col>
    </b-row>
</template>


<script>
import { computed, reactive, toRefs } from "@vue/composition-api";
import { momentJs } from "@/libs/utils/moment";

export default {
    name: "CenterBottom",
    props: {
        project_info: [Object],
    },
    setup(props) {
        const event = reactive({
            projectInfo: computed(() => {
                return props.project_info;
            }),
        });
        return {
            ...toRefs(event),
            momentJs,
        };
    },
};
</script>

<style scoped></style>
