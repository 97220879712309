<template>
    <b-card style="height: calc(30vh - 28px)" class="position-relative">
        <b-card-title> 负荷预测 </b-card-title>
        <b-button
            class="btn-icon rounded-circle"
            variant="flat-primary"
            style="position: absolute; top: 0.5rem; right: 0.5rem"
        >
            <b-icon-three-dots-vertical />
        </b-button>

        <div
            id="project-detail-prediction"
            style="height: calc(100% - 42px)"
        ></div>

        <div class="position-absolute" style="bottom: 3.2rem; left: 5rem">
            今日预计最大负荷
            {{
                predictionMax.egname
                    ? "(" + predictionMax.egname.replace("预测", "") + ")"
                    : ""
            }}
            <b-badge variant="light-danger" class="ml-1">{{
                predictionMax.value || "0"
            }}</b-badge>
            <small> kW @ {{ predictionMax.time || "00:00" }}</small>
        </div>
    </b-card>
</template>

<script>
import { Area } from "@antv/g2plot";
import {
    computed,
    onMounted,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api";
import { $themeColors } from "@themeConfig";
import { routerParams } from "@/libs/utils/routerParams";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
    name: "Prediction",
    components: {
        VuePerfectScrollbar,
    },
    props: ["project_prediction"],
    setup(props) {
        const event = reactive({
            project_id: computed(() => {
                return routerParams("project_id");
            }),
            projectPrediction: computed(() => {
                return props.project_prediction || [];
            }),
            predictionMax: { egname: "", value: 0, time: "" },
            energy_chart: null,
        });
        watch(
            () => event.projectPrediction,
            (data) => {
                if (data) {
                    if (event.energy_chart) {
                        event.energy_chart.changeData(data);
                    }
                    let max = { egname: "", value: 0, time: "" };
                    if (data) {
                        data.map((item) => {
                            if (item.egname !== "实测值") {
                                if (item.value >= max.value) {
                                    max = item;
                                }
                            }
                        });
                    }
                    event.predictionMax = max;
                }
            }
        );
        const chartInit = () => {
            const area = new Area("project-detail-prediction", {
                theme: {
                    colors10: Object.values($themeColors),
                },
                legend: {
                    itemName: {
                        style: { fill: "#82868b" },
                    },
                    position: "top",
                },
                data: [],
                xField: "time",
                yField: "value",
                seriesField: "egname",
                isStack: false,
                xAxis: {
                    label: {
                        autoHide: true,
                        autoRotate: false,
                    },
                    line: null,
                    grid: null,
                    tickLine: null,
                },
                yAxis: {
                    nice: true,
                    grid: {
                        line: {
                            style: {
                                stroke: "#82868b",
                                lineWidth: 1,
                                lineDash: [1, 6],
                                strokeOpacity: 0.7,
                            },
                        },
                    },
                },
                /* areaStyle: () => {
                    return {
                        fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
                    };
                }, */
                smooth: true,
                meta: {
                    value: {
                        alias: "数值",
                        formatter: (value) => {
                            return Math.round(value * 100) / 100;
                        },
                    },
                },
            });
            area.render();
            event.energy_chart = area;
        };
        onMounted(() => {
            chartInit();
            console.log("projectPrediction", event.projectPrediction);
        });

        return {
            ...toRefs(event),
        };
    },
};
</script>

<style scoped></style>
