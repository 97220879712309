<template>
    <b-card style="height: calc(70vh - 137px)">
        <b-card-title> 设备 </b-card-title>

        <div
            style="height: calc(100% - 42px)"
            class="d-flex flex-column justify-content-between"
        >
            <div>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <b-icon-hdd-rack-fill />
                        <span class="ml-1">制冷机</span>
                    </div>
                    <div>
                        <span class="bullet bullet-secondary bullet-sm"></span>
                        <span
                            class="bullet bullet-secondary bullet-sm ml-50"
                        ></span>
                        <span
                            class="bullet bullet-secondary bullet-sm ml-50"
                        ></span>
                    </div>
                </div>
                <div
                    class="d-flex align-items-center justify-content-between mt-75"
                >
                    <div>
                        <b-icon-gear-wide-connected />
                        <span class="ml-1">冷冻泵</span>
                    </div>
                    <div>
                        <span class="bullet bullet-secondary bullet-sm"></span>
                        <span
                            class="bullet bullet-secondary bullet-sm ml-50"
                        ></span>
                        <span
                            class="bullet bullet-secondary bullet-sm ml-50"
                        ></span>
                        <span
                            class="bullet bullet-secondary bullet-sm ml-50"
                        ></span>
                    </div>
                </div>
                <div
                    class="d-flex align-items-center justify-content-between mt-75"
                >
                    <div>
                        <b-icon-gear-wide-connected />

                        <span class="ml-1">冷却泵</span>
                    </div>
                    <div>
                        <span class="bullet bullet-secondary bullet-sm"></span>
                        <span
                            class="bullet bullet-secondary bullet-sm ml-50"
                        ></span>
                        <span
                            class="bullet bullet-secondary bullet-sm ml-50"
                        ></span>
                        <span
                            class="bullet bullet-secondary bullet-sm ml-50"
                        ></span>
                    </div>
                </div>
                <div
                    class="d-flex align-items-center justify-content-between mt-75"
                >
                    <div>
                        <b-icon-server />
                        <span class="ml-1">冷却塔</span>
                    </div>
                    <div>
                        <span class="bullet bullet-secondary bullet-sm"></span>
                        <span
                            class="bullet bullet-secondary bullet-sm ml-50"
                        ></span>
                        <span
                            class="bullet bullet-secondary bullet-sm ml-50"
                        ></span>
                    </div>
                </div>
            </div>

            <hr class="offsetMargin" />

            <b-row>
                <b-col cols="6">
                    <div
                        id="project-detail-equipment-liquid"
                        style="height: 8rem"
                    ></div>
                </b-col>
                <b-col cols="6" class="d-flex align-items-center">
                    <div>
                        <h4 class="my-0">350.1 <small>kWh</small></h4>
                        <label>制冷量</label>

                        <h5 class="mb-0 mt-50">62.5 <small>kWh</small></h5>
                        <label>耗电量</label>
                    </div>
                </b-col>
            </b-row>

            <hr class="offsetMargin" />

            <b-row>
                <b-col cols="6">
                    <label>冷冻水温度</label>
                    <div
                        class="d-flex align-items-center justify-content-between"
                    >
                        <b-avatar
                            badge="S"
                            badge-variant="primary"
                            variant="light-primary"
                        >
                            7.2
                        </b-avatar>

                        <b-avatar
                            badge="R"
                            badge-variant="primary"
                            variant="light-primary"
                        >
                            11.5
                        </b-avatar>
                    </div>
                </b-col>
                <b-col cols="6">
                    <label>冷却水温度</label>
                    <div
                        class="d-flex align-items-center justify-content-between"
                    >
                        <b-avatar
                            badge="S"
                            badge-variant="info"
                            variant="light-info"
                        >
                            32.5
                        </b-avatar>

                        <b-avatar
                            badge="R"
                            badge-variant="info"
                            variant="light-info"
                        >
                            27.4
                        </b-avatar>
                    </div>
                </b-col>
            </b-row>

            <b-row class="mt-1">
                <b-col cols="6">
                    <label>负荷率</label>
                    <b-progress
                        class="progress-bar-primary mb-1"
                        max="100"
                        show-value
                        value="65"
                        variant="primary"
                    />

                    <label>冷冻泵频率</label>
                    <b-progress
                        class="progress-bar-primary"
                        max="50"
                        show-value
                        value="45"
                        variant="primary"
                    />
                </b-col>

                <b-col cols="6">
                    <label>冷却泵频率</label>
                    <b-progress
                        class="progress-bar-info mb-1"
                        max="50"
                        show-value
                        value="47"
                        variant="info"
                    />

                    <label>冷却塔频率</label>
                    <b-progress
                        class="progress-bar-info"
                        max="50"
                        show-value
                        value="35"
                        variant="info"
                    />
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>

<script>
import { Column, Liquid } from "@antv/g2plot";
import { $themeColors } from "@themeConfig";
import { onMounted } from "@vue/composition-api";

export default {
    name: "Equipment",
    setup() {
        const liquidChartInit = () => {
            const liquidPlot = new Liquid(
                document.getElementById("project-detail-equipment-liquid"),
                {
                    theme: {
                        colors10: Object.values($themeColors),
                    },
                    color: () => {
                        return $themeColors.primary;
                    },
                    percent: 0.56,
                    radius: 1,
                    statistic: {
                        title: {
                            formatter: () => "SCOP",
                            style: ({ percent }) => ({
                                fontSize: "0.9rem",
                                fill: percent >= 0.65 ? "white" : "",
                            }),
                        },
                        content: {
                            style: ({ percent }) => ({
                                /* fontFamily: "LcdD", */
                                fontSize: "1.286rem",
                                fill: percent >= 0.5 ? "white" : "",
                            }),
                            customHtml: (container, view, { percent }) => {
                                const text = `${(percent * 10).toFixed(1)}`;
                                return `<div>${text}</div>`;
                            },
                        },
                    },
                }
            );
            liquidPlot.render();
        };
        const columnChartInit = () => {
            const stackedColumnPlot = new Column(
                "project-detail-equipment-column",
                {
                    theme: {
                        colors10: Object.values($themeColors),
                    },
                    data: [
                        {
                            name: "供冷量",
                            month: "一月",
                            energy: 0,
                        },
                        {
                            name: "供冷量",
                            month: "二月",
                            energy: 0,
                        },
                        {
                            name: "供冷量",
                            month: "三月",
                            energy: 100,
                        },
                        {
                            name: "供冷量",
                            month: "四月",
                            energy: 300,
                        },
                        {
                            name: "供冷量",
                            month: "五月",
                            energy: 1200,
                        },
                        {
                            name: "供冷量",
                            month: "六月",
                            energy: 1500,
                        },
                        {
                            name: "供冷量",
                            month: "七月",
                            energy: 2000,
                        },
                        {
                            name: "供冷量",
                            month: "八月",
                            energy: 1800,
                        },
                        {
                            name: "供冷量",
                            month: "九月",
                            energy: 1200,
                        },
                        {
                            name: "供冷量",
                            month: "十月",
                            energy: 300,
                        },
                        {
                            name: "供冷量",
                            month: "十一月",
                            energy: 0,
                        },
                        {
                            name: "供冷量",
                            month: "十二月",
                            energy: 0,
                        },
                        {
                            name: "供热量",
                            month: "一月",
                            energy: 1500,
                        },
                        {
                            name: "供热量",
                            month: "二月",
                            energy: 1200,
                        },
                        {
                            name: "供热量",
                            month: "三月",
                            energy: 800,
                        },
                        {
                            name: "供热量",
                            month: "四月",
                            energy: 100,
                        },
                        {
                            name: "供热量",
                            month: "五月",
                            energy: 0,
                        },
                        {
                            name: "供热量",
                            month: "六月",
                            energy: 0,
                        },
                        {
                            name: "供热量",
                            month: "七月",
                            energy: 0,
                        },
                        {
                            name: "供热量",
                            month: "八月",
                            energy: 0,
                        },
                        {
                            name: "供热量",
                            month: "九月",
                            energy: 100,
                        },
                        {
                            name: "供热量",
                            month: "十月",
                            energy: 500,
                        },
                        {
                            name: "供热量",
                            month: "十一月",
                            energy: 1000,
                        },
                        {
                            name: "供热量",
                            month: "十二月",
                            energy: 1200,
                        },
                    ],
                    isGroup: true,
                    xField: "month",
                    yField: "energy",
                    seriesField: "name",
                    /** 设置颜色 */
                    color: [$themeColors.warning, $themeColors.primary],
                    label: false,
                    xAxis: {
                        label: {
                            autoHide: true,
                            autoRotate: false,
                        },
                        line: null,
                        grid: null,
                        tickLine: null,
                    },
                    yAxis: {
                        line: null,
                        grid: null,
                        label: null,
                    },
                    columnStyle: {
                        radius: [5, 5, 5, 5],
                    },
                    legend: {
                        position: "top",
                    },
                }
            );

            stackedColumnPlot.render();
        };

        onMounted(() => {
            liquidChartInit();
            columnChartInit();
        });
    },
};
</script>

<style lang="scss" scoped>
.offsetMargin {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}
</style>
