<template>
    <div class="rounded shadow overflow-hidden" style="margin-bottom: 2rem">
        <b-carousel id="carousel-fade" controls fade indicators>
            <b-carousel-slide
                v-for="(carouselItem, carouselIndex) in projectInfo.images"
                :key="'carousel_' + carouselIndex"
                :style="'background: url(' + carouselItem + ');'"
                style="
                    background-size: cover;
                    background-position: center center;
                    width: 100%;
                    height: calc(70vh - 362px);
                "
            >
            </b-carousel-slide>
        </b-carousel>
    </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from "@vue/composition-api";
import store from "@/store";
import { PointLayer, Scene } from "@antv/l7";
import { GaodeMap } from "@antv/l7-maps";
import { iframeSrc } from "@/libs/utils/iframeSrc";

export default {
    name: "Center",
    props: {
        project_info: [Object],
    },
    setup(props) {
        const event = reactive({
            projectInfo: computed(() => {
                return props.project_info;
            }),
            map_style: computed(() => {
                const skin = store.state.appConfig.layout.skin;
                return skin === "dark" ? "dark" : "light";
            }),
            selected: 0,
            scene: null,
            selected_layer: null,
            project_list_layer: null,
            popup_layer: null,
        });
        /**
         * 监听地图主题，更新地图主题
         */
        watch(
            () => event.map_style,
            (map_style) => {
                event.scene.setMapStyle(map_style);
            }
        );
        watch(
            () => event.projectInfo,
            (value) => {
                if (value) {
                    setTimeout(() => {
                        map_init(value);
                    }, 1000);
                }
            }
        );

        /* 地图组件初始化 */
        const map_init = (projectInfo) => {
            console.log("event.projectInfo", projectInfo);
            event.scene = new Scene({
                id: "project-detail-map",
                map: new GaodeMap({
                    pitch: 0,
                    style: event.map_style,
                    center: [projectInfo.lng, projectInfo.lat],
                    token: "aeb928d0fa255249be59173a8d5fa016",
                    zoom: 7,
                    minZoom: 4,
                    maxZoom: 16,
                }),
            });
            event.scene.on("loaded", () => {
                let project_list = [];
                project_list.push(projectInfo);
                const pointLayer = new PointLayer()
                    .source(project_list, {
                        parser: {
                            type: "json",
                            x: "lng",
                            y: "lat",
                        },
                    })
                    .shape("circle")
                    .animate(true)
                    .size(30)
                    .color("#28c76f")
                    .style({
                        opacity: 0.3,
                    });
                event.scene.addLayer(pointLayer);
            });
        };

        return {
            ...toRefs(event),
            iframeSrc,
        };
    },
};
</script>

<style scoped></style>
